var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "row justify-center q-pt-sm" },
      [
        _c("QQButton", {
          staticClass: "q-mb-sm",
          attrs: {
            label: "Aggiorna elenco",
            color: "blue-grey",
            icon: "mdi-database-refresh-outline",
            size: "sm",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.aggiornaListaQuotazioni.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _c("hr"),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.is_loading,
            expression: "!is_loading",
          },
        ],
        staticClass:
          "text-center justify-center q-pa-md q-gutter-sm fixed-center",
        staticStyle: { "z-index": "1000" },
      },
      [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-2" },
        [
          _c(
            "q-list",
            { attrs: { bordered: "", padding: "" } },
            [
              _c(
                "q-item",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  attrs: { tag: "label" },
                },
                [
                  _c(
                    "q-item-section",
                    { attrs: { side: "", top: "" } },
                    [
                      _c("q-checkbox", {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onSelezionaCheck()
                          },
                        },
                        model: {
                          value: _vm.selezione_rapida,
                          callback: function ($$v) {
                            _vm.selezione_rapida = $$v
                          },
                          expression: "selezione_rapida",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [
                        _vm._v("Seleziona/Deseleziona tutto"),
                      ]),
                      _c("q-item-label", { attrs: { caption: "" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("q-separator", { attrs: { spaced: "" } }),
              _c(
                "q-item",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  attrs: { tag: "label" },
                },
                [
                  _c(
                    "q-item-section",
                    { attrs: { side: "", top: "" } },
                    [
                      _c("q-checkbox", {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onAggiornaFiltroTabella()
                          },
                        },
                        model: {
                          value: _vm.filtro_ricerca.attesa_lavorazione,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.filtro_ricerca,
                              "attesa_lavorazione",
                              $$v
                            )
                          },
                          expression: "filtro_ricerca.attesa_lavorazione",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Nuove quotazioni")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(
                          " Quotazioni appena richieste ma ancora non prese in carico "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  attrs: { tag: "label" },
                },
                [
                  _c(
                    "q-item-section",
                    { attrs: { side: "", top: "" } },
                    [
                      _c("q-checkbox", {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onAggiornaFiltroTabella()
                          },
                        },
                        model: {
                          value: _vm.filtro_ricerca.in_lavorazione,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtro_ricerca, "in_lavorazione", $$v)
                          },
                          expression: "filtro_ricerca.in_lavorazione",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("In lavorazione")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(
                          " Quotazioni prese in carico e in fase di definizione "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  attrs: { tag: "label" },
                },
                [
                  _c(
                    "q-item-section",
                    { attrs: { side: "", top: "" } },
                    [
                      _c("q-checkbox", {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onAggiornaFiltroTabella()
                          },
                        },
                        model: {
                          value: _vm.filtro_ricerca.definite,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtro_ricerca, "definite", $$v)
                          },
                          expression: "filtro_ricerca.definite",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Definite")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(
                          " Quotazioni definite e trasformate in preventivi "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  attrs: { tag: "label" },
                },
                [
                  _c(
                    "q-item-section",
                    { attrs: { side: "", top: "" } },
                    [
                      _c("q-checkbox", {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onAggiornaFiltroTabella()
                          },
                        },
                        model: {
                          value: _vm.filtro_ricerca.rifiutate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtro_ricerca, "rifiutate", $$v)
                          },
                          expression: "filtro_ricerca.rifiutate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Rifiutate")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(
                          " Quotazioni Rifiutate in fase di valutazione o dalle Compagnie "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  attrs: { tag: "label" },
                },
                [
                  _c(
                    "q-item-section",
                    { attrs: { side: "", top: "" } },
                    [
                      _c("q-checkbox", {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onAggiornaFiltroTabella()
                          },
                        },
                        model: {
                          value: _vm.filtro_ricerca.annullate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filtro_ricerca, "annullate", $$v)
                          },
                          expression: "filtro_ricerca.annullate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Annullate")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(" Quotazioni Annullate "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "col-12 col-md-10" }, [
        _c(
          "div",
          { staticClass: "q-px-md" },
          [
            _c("q-table", {
              staticClass: "my-sticky-header-table no-box-shadow q-mt-none",
              attrs: {
                title:
                  "Clicca su una Quotazione per attivare le opzioni di gestione",
                data: _vm.righe,
                "wrap-cells": "",
                columns: _vm.colonne,
                filter: _vm.filter,
                separator: "cell",
                "row-key": "id",
                "rows-per-page-options": [20, 40, 60],
              },
              on: { "row-click": _vm.selezionaRecordQuotazioni },
              scopedSlots: _vm._u([
                {
                  key: "top-right",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "panel-cerca" },
                        [
                          _c("q-input", {
                            staticStyle: { "min-width": "350px" },
                            attrs: {
                              dense: "",
                              debounce: "300",
                              outlined: "",
                              placeholder: "Cerca",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c("q-icon", { attrs: { name: "search" } }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "header",
                  fn: function (props) {
                    return [
                      _c(
                        "q-tr",
                        { attrs: { props: props } },
                        _vm._l(props.cols, function (col) {
                          return _c(
                            "q-th",
                            {
                              key: col.name,
                              staticStyle: {
                                "font-size": "14px",
                                "border-bottom": "1px SOLID #404040",
                                "background-color": "#ffcc99",
                                color: "#000",
                              },
                              attrs: { props: props },
                            },
                            [_c("strong", [_vm._v(_vm._s(col.label))])]
                          )
                        }),
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "body",
                  fn: function (props) {
                    return [
                      _c(
                        "q-tr",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: { props: props },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.selezionaRecordQuotazioni(
                                null,
                                props.row
                              )
                            },
                          },
                        },
                        [
                          _c("q-td", { attrs: { "auto-width": "" } }, [
                            _vm._v(" " + _vm._s(props.cols[0].value) + " "),
                          ]),
                          _c("q-td", {
                            attrs: { "auto-width": "" },
                            domProps: {
                              innerHTML: _vm._s(props.cols[1].value),
                            },
                          }),
                          _c("q-td", { attrs: { "auto-width": "" } }, [
                            _vm._v(" " + _vm._s(props.cols[2].value) + " "),
                          ]),
                          _c("q-td", { attrs: { "auto-width": "" } }, [
                            _vm._v(" " + _vm._s(props.cols[3].value) + " "),
                          ]),
                          _c("q-td", {
                            attrs: { "auto-width": "" },
                            domProps: {
                              innerHTML: _vm._s(props.cols[4].value),
                            },
                          }),
                          _c("q-td", {
                            attrs: { "auto-width": "" },
                            domProps: {
                              innerHTML: _vm._s(props.cols[5].value),
                            },
                          }),
                          _c("q-td", {
                            attrs: { "auto-width": "" },
                            domProps: {
                              innerHTML: _vm._s(_vm.getStatoQuotazione(props)),
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }